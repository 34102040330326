import store from "@/state/store.js";
import appConfig from "@/app.config";
import downloadcsv from "@/helpers/downloadcsv.js";
export default {
  page: {
    title: "Bundle history",
    meta: [{
      name: "description",
      content: appConfig.description
    }]
  },
  data() {
    return {
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 20,
      total: 0,
      search: "",
      dateData: [],
      exportLoading: false
    };
  },
  computed: {
    searchingTableData() {
      if (!this.search) {
        this.total = this.tableData.length;
        return this.tableData;
      }
      this.page = 1;
      return this.tableData.filter(data => data.name.toLowerCase().includes(this.search.toLowerCase()) || data.amount == this.search);
    },
    displayData() {
      this.total = this.searchingTableData.length;
      return this.searchingTableData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
    },
    disableButton() {
      if (this.tableData.length < 1) {
        return true;
      }
      return false;
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
    searchSpecificDate() {
      let payload = {
        from: this.dateData[0],
        to: this.dateData[1]
      };
      this.loading = true;
      store.dispatch("admin/reporting/getBundleHistory", payload).then(res => {
        this.tableData = res;
      }).finally(() => this.loading = false);
    },
    exportBundleHistory() {
      this.exportLoading = true;
      let payload = {
        from: this.dateData[0],
        to: this.dateData[1]
      };
      store.dispatch("admin/reporting/exportBundleHistory", payload).then(response => {
        downloadcsv(response.data, `bundle_history_from_${this.dateData[0]}_to_${this.dateData[1]}.csv`);
      }).catch(() => {
        this.$message.error("There was a problem exporting the data. Please try again.");
      }).finally(() => {
        this.exportLoading = false;
      });
    }
  },
  mounted() {
    this.loading = true;
    store.dispatch("admin/reporting/getBundleHistory", {
      to: "",
      from: ""
    }).then(res => this.tableData = res).finally(() => this.loading = false);
  }
};